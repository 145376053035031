import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { QuickRenewFormVm } from '../../modules/quick-renew/services/quick-renew-form.vm';
import { QuickRenewActions } from './quick-renew.actions';
import { bufferCount, map, switchMap, tap } from 'rxjs/operators';
import { updateTreeValidity } from '../../modules/share/form.utils';
import { PaymentActions } from '../payment/payment.actions';
import { concat, first, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { NotificationService } from '../../modules/share/notification/notification.service';
import { RequestErrorType } from '../generic-errors';

@Injectable({ providedIn: 'root' })
export class QuickRenewEffects {
  actions$ = inject(Actions);
  store = inject(Store);
  formVm = inject(QuickRenewFormVm);
  router = inject(Router);
  notification = inject(NotificationService);

  validateFormStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuickRenewActions.validateFormStep),
      switchMap(({ validateStep }) => {
        const validateMembershipNumber = validateStep === 'membershipNumber';
        const validatePaymentInfo = validateStep === 'paymentInfo';

        if (validateMembershipNumber) {
          const validateMembershipNumber$ = of(null).pipe(
            tap(() => this.store.dispatch(QuickRenewActions.validateMembership())),
            switchMap(() => this.actions$.pipe(ofType(QuickRenewActions.validateMembershipSucceeded), first())),
          );
          const validateRecostRenew$ = of(null).pipe(
            tap(() => this.store.dispatch(QuickRenewActions.recostValidate())),
            switchMap(() => this.actions$.pipe(ofType(QuickRenewActions.recostValidateSucceeded), first())),
          );
          const validators = [validateMembershipNumber$, validateRecostRenew$];

          return concat(...validators).pipe(
            bufferCount(validators.length),
            map(() => QuickRenewActions.validateFormStepSucceeded({ activeStep: 'membershipNumber' })),
          );
        }

        if (validatePaymentInfo) {
          const validatePaymentHolder$ = of(null).pipe(
            tap(() => this.store.dispatch(QuickRenewActions.validatePaymentHolder())),
            switchMap(() => this.actions$.pipe(ofType(QuickRenewActions.validatePaymentHolderSucceeded), first())),
          );

          const validatePayment$ = of(null).pipe(
            tap(() => this.store.dispatch(PaymentActions.validate())),
            switchMap(() => this.actions$.pipe(ofType(PaymentActions.validateSucceeded), first())),
          );

          const validateGenerateToken$ = of(null).pipe(
            tap(() => this.store.dispatch(PaymentActions.generateToken())),
            switchMap(() => this.actions$.pipe(ofType(PaymentActions.generateTokenSucceeded), first())),
          );

          const validatePay$ = of(null).pipe(
            tap(() => this.store.dispatch(QuickRenewActions.pay())),
            switchMap(() => this.actions$.pipe(ofType(QuickRenewActions.paySucceeded), first())),
          );

          const validators = [validatePaymentHolder$, validatePayment$, validateGenerateToken$, validatePay$];

          return concat(...validators).pipe(
            bufferCount(validators.length),
            map(() => QuickRenewActions.validateFormStepSucceeded({ activeStep: 'paymentInfo' })),
          );
        }

        return of(QuickRenewActions.validateFormStepSucceeded({ activeStep: validateStep }));
      }),
    ),
  );

  validateStepMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuickRenewActions.validateMembership),
      map(() => {
        if (this.formVm.membershipFormGroup.status !== 'VALID') {
          updateTreeValidity(this.formVm.membershipFormGroup);

          return QuickRenewActions.validateMembershipFailed();
        }

        return QuickRenewActions.validateMembershipSucceeded();
      }),
    ),
  );

  validateStepPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuickRenewActions.validatePaymentHolder),
      map(() => {
        const paymentCardHolderInvalid = this.formVm.paymentCardHolderFormGroup.status !== 'VALID';

        if (paymentCardHolderInvalid) {
          updateTreeValidity(this.formVm.paymentCardHolderFormGroup);

          return QuickRenewActions.validatePaymentHolderFailed();
        }

        return QuickRenewActions.validatePaymentHolderSucceeded();
      }),
    ),
  );

  resetForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuickRenewActions.resetPayment),
      tap(() => this.formVm.formGroup.reset()),
      map(() => PaymentActions.reset()),
    ),
  );

  showNotificationsMembershipNumberForm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(QuickRenewActions.validateMembershipFailed, QuickRenewActions.validateMembershipSucceeded),
        tap(() => this.notification.clear()),
        tap(() => {
          if (this.formVm.membershipFormGroup.controls.membershipNumber.invalid) {
            this.notification.show('Please complete Member Number');
          }
          if (this.formVm.membershipFormGroup.controls.lastName.invalid) {
            this.notification.show('Please complete Last Name');
          }
          if (this.formVm.membershipFormGroup.controls.zipcode.invalid) {
            this.notification.show('Please complete Zip Code');
          }
        }),
      ),
    { dispatch: false },
  );

  updateMembershipNumberFormAfterValidate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(QuickRenewActions.recostValidateFailed),
        tap(({ error }) => {
          if (error.type === RequestErrorType.MembershipInvalidZipCode) {
            this.formVm.membershipFormGroup.controls.zipcode.setErrors({ invalidZipCode: true });
          }

          if (error.type === RequestErrorType.MembershipInvalidLastName) {
            this.formVm.membershipFormGroup.controls.lastName.setErrors({ invalidLastName: true });
          }

          if ([RequestErrorType.MembershipInvalidNumber, RequestErrorType.MembershipNotFound].includes(error.type)) {
            this.formVm.membershipFormGroup.controls.membershipNumber.setErrors({ invalidMembership: true });
          }
        }),
      ),
    { dispatch: false },
  );

  redirectPaymentPagePage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(QuickRenewActions.validateFormStepSucceeded),
        tap(({ activeStep }) => activeStep === 'membershipNumber' && this.router.navigate(['renew', 'payment'])),
        tap(({ activeStep }) => activeStep === 'paymentInfo' && this.router.navigate(['renew', 'confirmation'])),
      ),
    { dispatch: false },
  );
}
